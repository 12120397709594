import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../common/Header';
import Footer from '../../common/Footer';

function Content400() {
  const imageStyle = {
    display: 'block',
    margin: '0 auto',
    animation: 'moveDown 1s ease-in-out infinite alternate',
  };

  const keyframes = `
    @keyframes moveDown {
      from {
        transform: translateY(0);
      }
      to {
        transform: translateY(50px); /* Ajustez cette valeur pour contrôler la distance de descente */
      }
    }
  `;

  // Injecter les keyframes dans le style de la page
  React.useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = keyframes;
    document.head.appendChild(styleSheet);
  }, []);

  return (
    <>     
      <div>
        <section className="">
          <div className="container">
            <div className="edu-error">
              <div className="thumbnail">
                <img 
                  src="/assets/images/others/404-imag.png" 
                  alt="404 Error" 
                  style={imageStyle}
                />
                <ul className="shape-group"></ul>
              </div>
              <div className="content">
                <h2 className="title">Looks like you got lost!</h2>
                <h4 className="subtitle">We couldn't find the page you were looking for.</h4>
                <Link 
                  to="/" 
                  className="edu-btn" 
                
                >
                  <i className="icon-west"></i>Back to Homepage
                </Link>              </div>
            </div>
          </div>
          {/* <ul className="shape-group">
            <li className="shape-1">
              <img src="../../assets/images/others/map-shape-2.png" alt="Shape" />
            </li>
            <li className="shape-3 scene">
              <img data-depth="2" src="../../assets/images/about/shape-13.png" alt="Shape" />
            </li>
            <li className="shape-4 scene">
              <img data-depth="-2" src="../../assets/images/counterup/shape-02.png" alt="Shape" />
            </li>
          </ul> */}
        </section>
      </div>    
    </>
  );
}

export default Content400;